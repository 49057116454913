
import Vue, { VueConstructor } from 'vue'
import AlertInfo from '@/components/AlertInfo.vue'
import Locale from '@/components/Locale.vue'
import SingleUploadCard from '@/components/SingleUploadCard.vue'
import { EventBus } from '@/plugins/eventBus'
import SubscriptionMixin from "@/mixins/SubscriptionMixin.vue"
import AlertError from "@/components/AlertError.vue"
import SubscriptionConfirmation from "@/components/subscription/SubscriptionConfirmation.vue"
import axios, { AxiosError } from "axios"


interface FormData {
  agency_id?: number | null,
  url?: string | null,
  cv_document?: string | null,
  sex?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  mobile_phone_number?: string | null,
  birth_date?: string | null,
  nationality?: string | null,
  postal_address?: string | null,
  postal_city?: string | null,
  postal_code?: string | null,
  postal_country?: string | null,
  postal_complement?: string | null,
  birth_department?: string | null,
  birth_city?: string | null,
  birth_country?: string | null
}

interface OCRResult {
  file: string,
  error: string,
  result: string
}

interface MappingCv {
  key: string,
  value: string
}

interface Agency {
  id: number,
  name: string,
  logo: string | null,
  prefix: string | null,
  ocr: number | null
}


export default (Vue as VueConstructor<Vue & InstanceType<typeof SubscriptionMixin>>).extend({
  name: 'SubscriptionDocuments',
  components: {
    AlertInfo,
    SingleUploadCard,
    AlertError,
    SubscriptionConfirmation,
    Locale
  },
  mixins: [SubscriptionMixin],
  props: {
    kiosk: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return{
      file: "",
      files: [],
      formData: {
        cv_document: null,
      } as FormData,
      waitRequest: false,
      rgpd: false,
      selectedSpace: {} as Agency,
      visibility: "",
      step: 1
    }
  },
  computed: {
    requiredRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    spaces() {
      let filteredOcrSpaces: Agency[] = []
      this.agencies.forEach((space: Agency) => {
        if (space.ocr === 1) {
          filteredOcrSpaces.push(space)
        }
      })
      return filteredOcrSpaces
    }
  },
  methods: {
    showLoader() {
      this.waitRequest = true
      this.visibility = "hidden-vs"
    },
    filterAgencies(item: Agency, queryText: string, itemText: string) {
      const words = [...queryText.toLocaleLowerCase()]
      const items = [...itemText.toLocaleLowerCase()]

      if(words.length < 2){
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      } else {
        return words.every(word => items.indexOf(word) > -1)
      }
    },
    onFileUploaded(ref: keyof FormData, data: string, ocr: OCRResult) {
      (this.formData[ref] as string) = data
      if (ocr.file.includes('error')) {
        this.displaySnackbar(ocr.error, 'error')
        this.resetData()
      } else {
        if (this.selectedSpace !== null) {
          if (ocr.result) {
            this.subscribeWorker(ocr, data)
          } else {
            this.resetData()
            this.errorSnackbar()
          }
        }
      }
    },
    subscribeWorker(ocr: OCRResult, filename: string) {
      let body: FormData = {
        agency_id: this.selectedSpace.id,
        url: this.$route.params.agencySubDomain + '.dispojob.pro',
        cv_document: filename
      }

      // affect returned data from OCRisation
      JSON.parse(ocr.result).forEach((dataOcr : MappingCv) => {
        Object.assign(body, dataOcr)
      })

      this.waitRequest = true
      // 1st try subscription
      axios.post('/v1/subscription/subscribe', body)
      .then(() => {
        // 1st try succeded, reset after 10s
        this.step = 2
        setTimeout(() => { this.resetData() }, 10000)
      })
      .catch((error1: AxiosError) => {
        // in case of subscription failure on first try
        // remove keys in error and retry
        if (error1.response?.data[0].includes("Invalid sex")) {
          body.sex = "M"
        }
        if (error1.response?.data[0].includes("Invalid first_name")) {
          body.first_name = "Non-détécté"
        }
        if (error1.response?.data[0].includes("Invalid last_name")) {
          body.last_name = "Non-détécté"
        }
        if (error1.response?.data[0].includes("Invalid email")) {
          body.email = null
        }
        if (error1.response?.data[0].includes("Invalid mobile_phone_number")) {
          body.mobile_phone_number = null
        }
        if (error1.response?.data[0].includes("Invalid birth_date")) {
          body.birth_date = null
        }
        if (error1.response?.data[0].includes("Invalid nationality")) {
          body.nationality = null
        }
        if (error1.response?.data[0].includes("Invalid postal_address")) {
          body.postal_address = null
        }
        if (error1.response?.data[0].includes("Invalid postal_city")) {
          body.postal_city = null
        }
        if (error1.response?.data[0].includes("Invalid postal_code")) {
          body.postal_code = null
        }
        if (error1.response?.data[0].includes("Invalid postal_country")) {
          body.postal_country = null
        }

        // not currently submitted erros
        if (error1.response?.data[0].includes("Invalid postal_complement")) {
          body.postal_complement = null
        }
        if (error1.response?.data[0].includes("Invalid birth_department")) {
          body.birth_department = null
        }
        if (error1.response?.data[0].includes("Invalid birth_city")) {
          body.birth_city = null
        }
        if (error1.response?.data[0].includes("Invalid birth_country")) {
          body.birth_country = null
        }

        // 2nd try subscription
        axios.post('/v1/subscription/subscribe', body)
        .then(() => {
          // 2nd try succeded, reset after 10s
          this.step = 2
          setTimeout(() => {  this.resetData() }, 10000)
        })
        .catch(() => {
          // in case of subscription failure on 2nd try
          // create a dummy subscription body and retry
          let finalBody = {
            agency_id: this.selectedSpace.id,
            url: this.$route.params.agencySubDomain + '.dispojob.pro',
            cv_document: filename,
            sex: body.sex,
            first_name: body.first_name,
            last_name: body.last_name,
            mobile_phone_number: null,
            email: filename.split('.')[0] + "@dispojob.com"
          }
          // 3rd try subscription
          axios.post('/v1/subscription/subscribe', finalBody)
          .then(() => {
            // 3nd try succeded, reset after 10s
            this.step = 2
            setTimeout(() => {  this.resetData() }, 10000)
          })
          .catch(() => {
            // full failure, redirect on standard subscription page
            this.errorSnackbar()
            setTimeout(() => {  this.resetData() }, 3000)
          })
        })
      })
    },
    errorSnackbar(){
      let errorMessage = this.$vuetify.lang.t("$vuetify.subscription_page.error.saving")
      if (!this.kiosk) {
        EventBus.$emit('snackbar',{message: errorMessage + this.$vuetify.lang.t("$vuetify.subscription_page.error.express"), color: 'error'})
        setTimeout(() =>{this.$router.push(`/inscription/${this.$route.params.agencySubDomain}`)}, 2000)
      } else {
        EventBus.$emit('snackbar',{message: errorMessage, color: 'error'})
      }
    },
    displaySnackbar(message:string, color:string) {
      EventBus.$emit('snackbar',{message: message, color: color})
      this.resetData()
    },
    resetData() {
      if (this.spaces.length > 1) {
        this.selectedSpace = {} as Agency
      }
      this.rgpd = false
      this.formData['cv_document'] = null
      this.file = ""
      this.files = []
      this.waitRequest = false
      this.visibility = ""
      this.step = 1
    }
  },
  watch: {
    agencies: function() {
      if (Object.keys(this.agencies).length === 1) {
        this.selectedSpace = (this.agencies[0] as Agency)
      }
    },
    spaces: {
      deep: true,
      handler: function (spaces) {
        if (spaces.length === 1) {
          this.selectedSpace = spaces[0]
        }
      }
    },
    selectedSpace: function() {
        let src = "dispojob_label.svg"
          if (this.selectedSpace.logo !== null && this.selectedSpace.logo !== undefined) {
            src = this.selectedSpace.logo
          }
          this.logo = ['development', 'test'].includes(process.env.NODE_ENV) ? require("@/assets/logos/" + src) : '/img/logos/' + src
        }
      }
    })
